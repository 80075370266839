// utils.js

export const execScript = async (script, context = {}) => {
    try {
        // Convertimos el contexto en variables accesibles en el eval
        const contextKeys = Object.keys(context);
        const contextValues = Object.values(context);

        // Marcamos que el script está corriendo
        sessionStorage.setItem("running", "true");

        // Creamos una función nueva donde se pasa el contexto como variables
        const wrappedFunction = new Function(...contextKeys, `return (async () => { ${script} })();`);

        // Ejecutamos la función con los valores del contexto y esperamos a que termine
        if (contextKeys.length > 0) await wrappedFunction(...contextValues);
        else await wrappedFunction();

        // Esperar activamente hasta que "running" sea false, con un timeout de seguridad
        const maxWaitTime = 5000; // Tiempo máximo de espera (5 segundos)
        const checkInterval = 100; // Cada cuánto revisamos si ha cambiado (100ms)
        let elapsedTime = 0;

        while (sessionStorage.getItem("running") === "true") {
            if (elapsedTime >= maxWaitTime) {
                //console.warn("⏳ Timeout: El semáforo 'running' sigue en 'true' después de 5 segundos.");
		sessionStorage.setItem("running", "false");
                break;
            }
            await new Promise(resolve => setTimeout(resolve, checkInterval));
            elapsedTime += checkInterval;
        }

    } catch (error) {
        console.error("Error executing script:", error);
    } finally {
        // Asegurar que se marque como finalizado
        sessionStorage.setItem("running", "false");
    }
};

const waitForExecutionToFinish = async (interval = 100) => {
    while (sessionStorage.getItem("running") === "true") {
        await new Promise(resolve => setTimeout(resolve, interval));
    }
};

// Function to parse form types
export const parseForm = (formString, language) => {
  if (!formString || !formString[language]) return [];

  return formString[language].split('\n').map(line => {
    const [label, description, variable, type] = line.split('||');
    let fieldType = 'text';
    let maxLength, pattern;

    if (type) {
      if (type.startsWith('string')) {
        maxLength = type.match(/\((\d+)\)/)?.[1];
      } else if (type.startsWith('number')) {
        if (type.includes(':')) {
          const [digits, range] = type.split(':');
          maxLength = digits.match(/\((\d+)\)/)?.[1];
          pattern = `\\d{${maxLength}}`;
        } else {
          maxLength = type.match(/\((\d+)\)/)?.[1];
          fieldType = 'number';
        }
      } else if (type.startsWith('float')) {
        const [digits, decimals] = type.match(/\((\d+),(\d+)\)/).slice(1);
        maxLength = digits;
        pattern = `\\d{1,${digits}}(\\.\\d{1,${decimals}})?`;
      } else if (type === 'date') {
        fieldType = 'date';
      } else if (type === 'datetime') {
        fieldType = 'datetime-local';
      }
    }

    return {
      label,
      description,
      name: variable,
      type: fieldType,
      maxLength,
      pattern,
    };
  });
};

export function saveToSessionStorage(config, form, assistant) {
    if (config.exposeForm && form) {
        sessionStorage.setItem('vawf_storageForm', JSON.stringify(form));
    }
    if (config.exposeAssistant && assistant) {
        sessionStorage.setItem('vawf_storageAssistant', JSON.stringify(assistant));
    }
}

// Parche para forzar la carga de scripts externos antes de lanzar a ejecutar scripts del asistente
export function loadScripts(scriptUrls) {
    return new Promise((resolve, reject) => {
        let loadedScripts = 0;

        scriptUrls.forEach(scriptUrl => {
            const script = document.createElement("script");
            script.src = scriptUrl;
            script.async = true;
            script.onload = () => {
                loadedScripts++;
                console.log(`📜 Script cargado: ${scriptUrl}`);
                if (loadedScripts === scriptUrls.length) {
                    console.log("✅ Todos los scripts han sido cargados.");
                    resolve();
                }
            };
            script.onerror = () => reject(`❌ Error al cargar: ${scriptUrl}`);
            document.body.appendChild(script);
        });
    });
}

