import '../../styles/App.css';
import React, { useEffect,  useState } from 'react';
import Popup from '../../components/form/Popup';
import { execScript } from '../../utils/utils';

// Primera pantalla
function Result(props) {
   const [isPopupOpen, setIsPopupOpen] = useState(false);//Tenemos formulario o no
   const [visibleQuestions, setVisibleQuestions] = useState(''); //Qué preguntas mostramos

  /* Si hay formulario a nivel de respuesta se ha de mostrar al principio */
  useEffect(() => {
    if (!props.hidden && props.result.form) {
      setVisibleQuestions(props.result.form.all);
      setIsPopupOpen(true);
    }
  }, [props.hidden, props.result.form]);


  if(props.hidden){
    return '';
  }else{
    const { submit, link, button } = props.result;
    const buttonText = button?.[props.language] || 'Tramitar';
    const submitUrl = submit?.[props.language];
    const linkUrl = link?.[props.language];

    //Botón de enviar del formulario
    //Se guardan las respuestas y vamos a la siguiente pregunta
    const handleComplete = (surveyResults) => {
      props.setSavedAnswers(surveyResults); // Guardar las respuestas
      //Si hay script, ahora se ha de ejecutar
      const scriptToExecute = props.result.script && (props.result.script[props.language] || Object.values(props.result.script)[0]);
      if (scriptToExecute) {
        var answers = props.savedAnswers;
        execScript(scriptToExecute, { data:answers });
      }
    };


    
    return (
      <div style={{
        width: props.isDuplicate ? '50%' : '100%',
        marginLeft: props.right ? '30px' : '0px',
        marginRight: props.right ? '30px' : '0px'
      }}>
         {isPopupOpen && (
          <Popup
            form={props.form}
            onClose={() => setIsPopupOpen(false)}
            onComplete={handleComplete}
            textButton={props.textButton}
            savedAnswers={props.savedAnswers}
            visibleQuestions={visibleQuestions}
          />
        )}
        <div className="description" dangerouslySetInnerHTML={{ __html: props.text }}></div>
            <br />
            <div>
              {props.userPath.map((item, index) =>
                item.selectedOption.documentation ? (
                  <div key={index}>
                     <div dangerouslySetInnerHTML={{ __html: `<strong>${item.selectedOption.documentation[props.language].replace(/\n/g, '<br />')}</strong>` }}></div>
                  </div>
                ) : null
              )}
            </div>
            <br />
            {props.result.image && <img src={props.getMediaContent('image', props.result.image)} alt="Image" className="embedded-media" onClick={() => props.handleImageClick(props.getMediaContent('image', props.result.image))} />}
              {props.result.video && props.renderVideoEmbed(props.result.video)}
              {/* Botón basado en "submit" o "link" */}
              {submitUrl ? (
                // Si existe "submit", renderiza un formulario con datos ocultos.
                <form action={submitUrl} method="POST" style={{ display: 'inline' }}>
                  {/* Serializa los datos de savedAnswers en campos ocultos */}
                  {Object.entries(props.savedAnswers).map(([key, value]) => (
                    <input
                      key={key}
                      type="hidden"
                      name={key}
                      value={typeof value === 'object' ? JSON.stringify(value) : value}
                    />
                  ))}
                  <button type="submit" className="button_start">
                    {buttonText}
                  </button>
                </form>
              ) : linkUrl ? (
                // Si existe "link", redirige mediante un enlace.
                <a href={linkUrl} target="_blank" rel="noopener noreferrer">
                  <button className="button_start">{buttonText}</button>
                </a>
              ) : null}
              <a href=""><button className="button_start" onClick={props.resetFlow}>Reset</button></a>
      </div>
     
    );
  }
 
}

export default Result;

